import React from 'react';
import './Projects.css';
import p1 from "./p1.jpg"
import p2 from "./p2.png"
import p3 from "./p3.png"
import p4 from "./p4.png"
import p5 from "./p5.png"
import p6 from "./p6.png"
import p7 from "./p7.png"
import p8 from "./p8.png"
import p9 from "./p9.png"
import p10 from "./p10.png"
import p11 from "./p11.png"

// Example array of projects with photos
const projects = [
  {
    title: 'LecturePal',
    info: 'LecturePal is a AI notetaking app designed to help university students save time and improve their in-class retention. It features a user login/register system, user storage, and password recovery that is common for many web applications. Users can upload lecture slides and click a button that generates clean precise notes utiilizing AI in seconds.',
    link: 'https://lecturepal.ca/',
    photo: p2, // Replace with actual image URLs
  },
  {
    title: 'HR Proactive',
    info: 'HR Proactive is a leading HR firm in Canada we have worked with on a number of projects. This site in particular is designed to sell an online training product used by numerous well-known Canadian Companies.',
    link: 'https://antispamtraining.com/',
    photo: p3, // Replace with actual image URLs
  },
  {
    title: 'FitVault',
    info: 'FitVault is a fitness app that allows trainers to post workouts for there clients online for free. FitVault is a complete web solution with Apple App Store, Google Play Store, and website functionality.',
    link: 'https://apps.apple.com/za/app/fitvault-fitness-app/id6443820434',
    photo: p4, // Replace with actual image URLs
  },
  {
    title: 'Speed Habit Tracker',
    info: 'Built for a high-performance hockey school in Burlington, ON, this habit tracker app helps coaches and athletes stay on top of daily performance metrics. With secure login, password recovery, and full admin controls, it has never been easier to track progress and visualize growth with powerful, real-time graphs.',
    link: 'https://apps.apple.com/za/app/speed-habit-tracker/id6670226720?platform=iphone',
    photo: p5, // Replace with actual image URLs
  },
  {
    title: 'BulletPlan',
    info: 'Designed for high-performers, BulletPlan offers secure local storage and a daily refresh cycle to keep your planning streamlined and distraction-free.',
    link: 'https://apps.apple.com/za/app/bulletplan/id1658811237?platform=iphone',
    photo: p6, // Replace with actual image URLs
  },
  {
    title: 'Next Play Hockey',
    info: 'Next Play Hockey is a Toronto-based hockey company offering a seamless way to book video coaching sessions online. Our clean, user-friendly platform lets players and parents easily schedule sessions, make secure payments, and stay on top of their development — all in one place. This site is rank 1 on google search SEO.',
    link: 'https://nextplayhockey.com/',
    photo: p7, // Replace with actual image URLs
  },
  {
    title: 'Snap Site',
    info: 'Created by a Hamilton-based founder, Snap Site is the 1-minute website builder with ultra-simple hosting for just $2/month — fast, affordable, and built to get you online instantly.',
    link: 'https://snapsite.ca/',
    photo: p8, // Replace with actual image URLs
  },
  {
    title: 'Global Automation Technology',
    info: 'A company’s website is its first impression — and for national leaders like Global Sutomation Technology, that impression had to match their world-class automation work with clients like Tesla and Amazon. We were proud to bring their vision online with a custom-built site that reflects their scale, innovation, and professionalism.',
    link: 'https://gatech.ca/',
    photo: p9, // Replace with actual image URLs
  },
  {
    title: 'Daily Dabble',
    info: 'Daily Dabble is your one-stop app for daily brain teasers — featuring a collection of the world’s most popular daily games, including NYT’s Wordle and more. Stay sharp, have fun, and make brain training part of your daily routine.',
    link: 'https://apps.apple.com/za/app/dailydabble/id1621862336?platform=iphone',
    photo: p10, // Replace with actual image URLs
  },
  {
    title: 'Snap Carts',
    info: 'Snap Carts is a leading receipt tracker and cart management app with over 22,000 downloads annually across Asia. Designed to keep users organized at scale, Snap Carts helps individuals and businesses track purchases, manage carts, and simplify spending — all in one intuitive platform.',
    link: 'https://apps.apple.com/za/app/snapcarts/id1661589663',
    photo: p11, // Replace with actual image URLs
  },
  // Add more projects as needed
];

const Projects = () => {
  return (
    <div className="projects">  
     <h1>Past Projects</h1>
      <p>Check out our past projects! Click the project to see it live.</p>
      {projects.map((project, index) => (
        <a 
          key={index}
          href={project.link}
          className="project-box"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="project-content">
            <div className="project-image">
              <img src={project.photo} alt={project.title} />
            </div>
            <div className="project-text">
              <h2>{project.title}</h2>
              <p>{project.info}</p>
            </div>
          </div>
        </a>
      ))}
      <p style={{padding:"30px"}}>For a complete list of over 50 websites and 15 applications, contact us today!</p>
    </div>
  );
};

export default Projects;


