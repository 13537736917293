import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from "../dudigital.jpg"
const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      <header className="header">
        <div className="logo">
        <img 
        src={logo} 
        alt="Logo" 
        style={{ maxWidth: '250px', width: '100%', height: 'auto' }} 
      />
        </div>
        <div className="menu-icon" onClick={toggleSidebar}>
          &#9776;
        </div>
        <nav className="nav-menu">
          <Link to="/">Home</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/projects">Past Projects</Link>
          <Link to="/reviews">Reviews</Link>
        </nav>
      </header>
      <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
        <button className="close-btn" onClick={toggleSidebar}>
          &times;
        </button>
        <Link to="/" onClick={toggleSidebar}>Home</Link>
        <Link to="/contact" onClick={toggleSidebar}>Contact</Link>
        <Link to="/projects" onClick={toggleSidebar}>Past Projects</Link>
        <Link to="/reviews" onClick={toggleSidebar}>Reviews</Link>
      </div>
      <div className={`backdrop ${sidebarOpen ? 'open' : ''}`} onClick={toggleSidebar}></div>
    </>
  );
};

export default Header;
