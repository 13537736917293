import React from 'react';
import './Reviews.css';

const reviews = [
  {
    name: "Sarah M.",
    review: "Matt made the entire process seamless. The app works flawlessly and we saw a revenue jump within the first month!",
  },
  {
    name: "Jason L.",
    review: "Our site went live fast and looked so clean. Clients immediately responded and bookings skyrocketed. 5 stars all the way!",
  },
  {
    name: "Ravi P.",
    review: "I can't believe how smooth everything was. Matt knows what he's doing — our web traffic and sales doubled!",
  },
  {
    name: "Emily R.",
    review: "Working with Matt was a dream. Professional, creative, and the app he built brought us more clients than we imagined.",
  },
  {
    name: "Tyson W.",
    review: "Five stars isn't enough. Matt's work helped automate our business and bring in consistent income. Worth every penny!",
  },
];

const Reviews = () => {
  return (
    <div className="reviews">
      <h1 className="reviews-title">⭐ What People Are Saying</h1>
      <p className="reviews-subtitle">Real feedback from happy clients who trusted Matt with their digital success.</p>
      <div className="reviews-container">
        {reviews.map((r, i) => (
          <div key={i} className="review-card">
            <div className="stars">★★★★★</div>
            <p className="review-text">"{r.review}"</p>
            <p className="review-author">— {r.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reviews;
